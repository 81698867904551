import(/* webpackMode: "eager", webpackExports: ["CohesionContext","CohesionProvider","useMonarch","useMonarchRule","useMonarchRequest","useMonarchSlot","useMonarchSlotContext","useMonarchPreampPlacement","useMonarchPreampPlacementContext","usePreamp","usePreampPlacement","usePreampPlacementExists","usePreampAsset","usePreampAssetExists","MonarchSlotContext","MonarchSlot","MonarchPreampPlacementContext","MonarchPreampPlacement"] */ "/workspace/node_modules/@highereducation/cohesion-utils-react/dist/CohesionProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientOnly"] */ "/workspace/src/components/Cohesion/ClientOnly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Cohesion/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CohesionReady"] */ "/workspace/src/components/CohesionReady/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MonarchRuleProvider"] */ "/workspace/src/monarch/rules/MonarchRulesProvider.tsx");

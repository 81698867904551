import { tagular } from "@highereducation/cohesion-utils-react";
import { BeamAttributes } from "@/components/CohesionReady/eventing/types";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { ProductViewed } from "@/hooks/eventing/types/redventures.ecommerce.v1.ProductViewed";
import { ProductClicked } from "@/hooks/eventing/types/redventures.ecommerce.v1.ProductClicked";

/**
 * Ideally we find opportunities to share code between this approach
 * and the hook implementation for consistency.
 */

type Events = ElementViewed | ElementClicked | ProductViewed | ProductClicked;

function beamIt(event: Events) {
  tagular("beam", event as Record<string, any>);
}

export const beamProductClicked = (attributes: BeamAttributes) => {
  const event: ProductClicked = {
    "@type": "redventures.ecommerce.v1.ProductClicked",
    product: {
      brand: attributes["beam-brand"],
      formatSubtype: attributes["beam-format-subtype"],
      formatType: attributes["beam-format-type"],
      location: attributes["beam-location"],
      name: attributes["beam-name"],
      variant: attributes["beam-variant"],
      position: Number(attributes["beam-position"]),
      category: attributes["beam-category"],
      coupon: attributes["beam-coupon"],
      price: Number(attributes["beam-price"]),
      productId: attributes["beam-product-id"],
      quantity: Number(attributes["beam-quantity"]),
      text: attributes["beam-text"],
      sku: attributes["beam-sku"],
      upc: attributes["beam-upc"],
    },
    outboundUrl: attributes["beam-outbound-url"],
    actionOutcome: "click",
  };
  beamIt(event);
};

export const beamProductViewed = (attributes: BeamAttributes) => {
  const event: ProductViewed = {
    "@type": "redventures.ecommerce.v1.ProductViewed",
    product: {
      brand: attributes["beam-brand"],
      formatSubtype: attributes["beam-format-subtype"],
      formatType: attributes["beam-format-type"],
      location: attributes["beam-location"],
      name: attributes["beam-name"],
      variant: attributes["beam-variant"],
      position: Number(attributes["beam-position"]),
      category: attributes["beam-category"],
      coupon: attributes["beam-coupon"],
      price: Number(attributes["beam-price"]),
      productId: attributes["beam-product-id"],
      quantity: Number(attributes["beam-quantity"]),
      text: attributes["beam-text"],
      sku: attributes["beam-sku"],
      upc: attributes["beam-upc"],
    },
  };
  beamIt(event);
};

export const beamElementClicked = (attributes: BeamAttributes) => {
  const event: ElementClicked = {
    "@type": "redventures.usertracking.v3.ElementClicked",
    webElement: {
      elementType: attributes["beam-element-type"],
      htmlId: attributes["beam-html-id"],
      location: attributes["beam-location"],
      name: attributes["beam-name"],
      position: attributes["beam-position"],
      text: attributes["beam-text"],
    },
    outboundUrl: attributes["beam-outbound-url"],
  };
  beamIt(event);
};

export const beamElementViewed = (attributes: BeamAttributes) => {
  const event: ElementViewed = {
    "@type": "redventures.usertracking.v3.ElementViewed",
    webElement: {
      elementType: attributes["beam-element-type"],
      htmlId: attributes["beam-html-id"],
      location: attributes["beam-location"],
      name: attributes["beam-name"],
      position: attributes["beam-position"],
      text: attributes["beam-text"],
    },
  };
  beamIt(event);
};

export const beamIdentify = (attributes: BeamAttributes) => {
  const event = {
    "@type": "core.Identify.v1",
    externalIds: [
      {
        id: attributes["segment-anonymous-id"],
        type: "segment_anonymous_id",
        collection: "users",
        encoding: "none",
      },
      // The following data is meant for 2U's Segment which is a Make Destinaiton
      // Segment will perform Idendity resolution based on this field and merge the user profiles,
      // therefore the billing on Segment will be charged for one user and not two
      {
        id: attributes["cohesion-anonymous-id"],
        type: "cohesion_anonymous_id",
        collection: "users",
        encoding: "none",
      },
    ],
    traits: {},
  };
  tagular("beam", event);
};
